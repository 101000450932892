import { useEffect, type FC } from 'react'
import { useRoutes } from 'react-router-dom'
// import { hotjar } from 'react-hotjar'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { RTL } from './components/RTL'
import { SplashScreen } from './components/SplashScreen'
import { Toaster } from './components/Toaster'
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context'
import { AuthConsumer, AuthProvider } from './contexts/auth/jwt-context'
import { useNprogress } from './hooks/use-nprogress'
import { routes } from './routes'
import { store } from './store'
import { createTheme } from './theme'
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { Error500 } from '@/pages/Error500'

import './locales/i18n'
import { ConfirmContextProvider } from './components/ConfirmModal'
import { defaultConfig } from '@/config'
import * as Sentry from '@sentry/browser'

export const App: FC = () => {
  useNprogress()

  const element = useRoutes(routes)

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      void navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => console.log('scope is: ', registration.scope))
    }

    if (defaultConfig().envMode === 'production') {
      Sentry.init({
        dsn: defaultConfig().sentryDsn,
        environment: defaultConfig().envMode
          ? defaultConfig().envMode
          : 'development',
        integrations: [
          Sentry.replayCanvasIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }
  }, [])

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 30000,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => console.error('Error: ', error),
    }),
  })

  return (
    <ErrorBoundary FallbackComponent={Error500}>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <AuthConsumer>
              {(auth) => (
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      // Prevent theme flicker when restoring custom settings from browser storage
                      if (!settings.isInitialized) {
                        // return null;
                      }

                      const theme = createTheme({
                        colorPreset: settings.colorPreset,
                        contrast: settings.contrast,
                        direction: settings.direction,
                        paletteMode: settings.paletteMode,
                        responsiveFontSizes: settings.responsiveFontSizes,
                      })

                      // Prevent guards from redirecting
                      const showSplashScreen = !auth.isInitialized

                      return (
                        <QueryClientProvider client={queryClient}>
                          <ThemeProvider theme={theme}>
                            <Helmet>
                              <meta
                                name="color-scheme"
                                content={settings.paletteMode}
                              />
                              <meta
                                name="theme-color"
                                content={theme.palette.neutral[900]}
                              />
                            </Helmet>
                            <RTL direction={settings.direction}>
                              <ConfirmContextProvider>
                                <CssBaseline />
                                {showSplashScreen ? (
                                  <SplashScreen />
                                ) : (
                                  <>{element}</>
                                )}
                                <Toaster />
                              </ConfirmContextProvider>
                            </RTL>
                          </ThemeProvider>
                        </QueryClientProvider>
                      )
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              )}
            </AuthConsumer>
          </AuthProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </ErrorBoundary>
  )
}
